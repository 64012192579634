import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Wrap,
  WrapItem,
  Button,
  Stack,
  Heading,
  Image,
  HStack,
  Flex,
  Center,
  Tooltip,
  Box,
  Badge,
  Container,
  Text,
  TabPanel,
  Tabs,
  Tab,
  TabList,
  List,
  TabPanels,
  ListItem,
  Icon,
} from "@chakra-ui/react";
import { BsGrid3X2, BsList } from "react-icons/bs";
import { Link } from "gatsby";

import Summary from "./Summary";

const ArticleList = (props) => {
  const {
    articles,
    breadcrumb,
    label,
    loadMoreText,
    image,
    articlesRef,
    page,
    alt,
    filters,
    type: pageType,
    description,
  } = props;

  const [offset, setOffset] = useState(0);
  const pageSize = Number(page) || 12;
  const [publishedArticles, setArticles] = useState(
    articles.slice(0, pageSize)
  );

  const increaseOffset = () => {
    setArticles(articles.slice(0, offset + pageSize + pageSize));
    setOffset(offset + pageSize);
  };

  const filterArticles = (type) =>
    setArticles(
      articles.filter((a) =>
        a.categories.some((c) => c.toLowerCase() === type.toLowerCase())
      )
    );

  if (!articles || articles.length === 0)
    return (
      <Stack
        borderWidth="1px"
        maxWidth="7xl"
        borderRadius="lg"
        ref={articlesRef}
        p="10vh"
      >
        {breadcrumb && (
          <Breadcrumb>
            {breadcrumb.map((bc) => (
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to={bc[1]}>
                  {bc[0]}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{label}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <Heading size="lg" color="gray">
          There are no articles to display
        </Heading>
      </Stack>
    );

  const pageLabel = pageType ? (
    <>
      {pageType}: <br /> {label}
    </>
  ) : (
    label
  );

  return (
    <Center>
      <Stack
        borderWidth="1px"
        maxWidth="7xl"
        borderRadius="lg"
        ref={articlesRef}
        p="2em"
      >
        {breadcrumb && (
          <Breadcrumb>
            {breadcrumb.map((bc) => (
              <BreadcrumbItem>
                <BreadcrumbLink as={Link} to={bc[1]}>
                  {bc[0]}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>{label}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
        <HStack>
          {image && (
            <Tooltip label={alt}>
              <Image src={image} maxH="200px" alt={alt} />
            </Tooltip>
          )}
          {label && (
            <Heading size="lg" color="gray">
              {pageLabel}
            </Heading>
          )}
          {description && (
            <Container maxW="3xl">
              <Text>{description}</Text>
            </Container>
          )}

          {filters && (
            <Container maxW="3xl">
              <Wrap>
                {filters.map((category, index) => {
                  const articleCount = articles.filter((a) =>
                    a.categories.some(
                      (c) => c === category.value || c === category.label
                    )
                  ).length;
                  return (
                    <Tooltip label={category.description}>
                      <Box
                        key={`${category.value}-${index}`}
                        as={Button}
                        variant="outline"
                        size="md"
                        onClick={() => filterArticles(category.label)}
                        margin="2px"
                        disabled={articleCount === 0}
                      >
                        <HStack>
                          <Text>{category.label}</Text>
                          <Badge borderRadius="full" p="1" colorScheme="teal">
                            {articleCount}
                          </Badge>
                        </HStack>
                      </Box>
                    </Tooltip>
                  );
                })}
              </Wrap>
            </Container>
          )}
        </HStack>
        <Tabs align="end">
          <TabList>
            <Tab>
              <Icon as={BsGrid3X2} />
            </Tab>
            <Tab>
              <Icon as={BsList} />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Wrap>
                {publishedArticles.map((article, index) => {
                  const {
                    doi,
                    history,
                    submissionTypes,
                    authors,
                    figure,
                    title,
                    citations,
                    // pmcViews,
                  } = article;
                  let figureFile;

                  if (figure && figure.url) {
                    const extension = figure.url.split(".").pop();
                    const articleId =
                      doi &&
                      doi.includes("/") &&
                      doi.split("/").pop().replace(/\./g, "-");

                    const figureExt = extension === "csv" ? "png" : extension;

                    figureFile = `/static/thumbnails/${articleId}.${figureExt}`;
                  }

                  const isCorrection = submissionTypes.some(
                    (c) =>
                      c === "corrigendum" ||
                      c === "erratum" ||
                      c === "retraction"
                  );

                  return (
                    <WrapItem key={`summary-${index}`}>
                      <Summary
                        authors={authors}
                        categories={submissionTypes}
                        doi={doi}
                        figure={figureFile}
                        publishedDate={history.published}
                        title={title}
                        isCorrection={isCorrection}
                        citations={citations}
                        // pmcViews={pmcViews}
                      />
                    </WrapItem>
                  );
                })}
              </Wrap>
              {loadMoreText && (
                <Flex justify="flex-end">
                  <Button
                    margin="1em"
                    onClick={increaseOffset}
                    disabled={offset >= publishedArticles.length}
                    float="right"
                  >
                    {loadMoreText}
                  </Button>
                </Flex>
              )}
            </TabPanel>
            <TabPanel>
              <List>
                {publishedArticles.map((article, index) => {
                  const {
                    doi,
                    history,
                    submissionTypes,
                    title,
                    authors,
                    figure,
                  } = article;

                  return (
                    <ListItem key={`summary-${index}`}>
                      {" "}
                      <Summary
                        authors={authors}
                        categories={submissionTypes}
                        doi={doi}
                        figure={figure && figure.url}
                        publishedDate={history.published}
                        title={title}
                        isList={true}
                      />
                    </ListItem>
                  );
                })}
              </List>
              {loadMoreText && (
                <Flex justify="flex-end">
                  <Button
                    margin="1em"
                    onClick={increaseOffset}
                    disabled={offset >= publishedArticles.length}
                    float="right"
                  >
                    {loadMoreText}
                  </Button>
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Center>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        })
      ),
      categories: PropTypes.arrayOf(PropTypes.string),
      doi: PropTypes.string,
      figure: PropTypes.shape({ url: PropTypes.string }),
      pdfUrl: PropTypes.string,
      publishedDate: PropTypes.string,
      reviewedBy: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
ArticleList.defaultProps = {
  articles: [],
};

export default ArticleList;
